var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group mb-md-0 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.classroom,
                      expression: "filters.classroom",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "search", placeholder: "Turma" },
                  domProps: { value: _vm.filters.classroom },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "classroom", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Data das aulas",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "date", $$v)
                      },
                      expression: "filters.date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Unidade",
                      options: _vm.optionsUnits,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "unit", $$v)
                      },
                      expression: "filters.unit",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      placeholder: "Turno",
                      options: _vm.optionsShifts,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.shift,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "shift", $$v)
                      },
                      expression: "filters.shift",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.optionsTypes,
                      clearable: true,
                      searchable: false,
                      placeholder: "Tipo",
                    },
                    model: {
                      value: _vm.filters.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "type", $$v)
                      },
                      expression: "filters.type",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-row mt-1" }, [
            _c("div", { staticClass: "col-md-11" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c(
                    "v-select",
                    {
                      ref: "selectTeacher",
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Digite o nome do professor",
                        options: _vm.optionsTeachers,
                        multiple: "",
                      },
                      on: { search: _vm.fetchTeachers },
                      model: {
                        value: _vm.filters.teachers,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "teachers", $$v)
                        },
                        expression: "filters.teachers",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "no-options" },
                          on: {
                            click: function ($event) {
                              _vm.$refs.selectTeacher.open = false
                            },
                          },
                          slot: "no-options",
                        },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  staticStyle: { padding: "14px !important" },
                  attrs: { type: "button" },
                  on: { click: _vm.search },
                },
                [
                  !_vm.btnSearch
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._m(0),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0 mb-1" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        options: _vm.perPageOptions,
                        clearable: false,
                        searchable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.$can(
                      "Ponto do Professor - Gerar Relatório",
                      "Pedagógico"
                    )
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn btn-block btn-success",
                                attrs: {
                                  title:
                                    "Apenas assinaturas confirmadas podem ser geradas relatórios",
                                  type: "button",
                                },
                                on: { click: _vm.generateReport },
                              },
                              [_vm._v(" Gerar Relatório de Ponto ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "no-border-collapse": true,
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              striped: "",
              "sort-by": "start_time",
              busy: _vm.loading,
              hover: "",
              id: "printMe",
              "tbody-tr-class": _vm.rowClass,
              "empty-text": "Nenhuma aula cadastrada até o momento.",
            },
            scopedSlots: _vm._u([
              {
                key: "head(select)",
                fn: function (data) {
                  return [
                    _c("b-form-checkbox", {
                      attrs: { size: "lg" },
                      on: {
                        change: function ($event) {
                          return _vm.selectAll(data)
                        },
                      },
                      model: {
                        value: _vm.isSelectedAll,
                        callback: function ($$v) {
                          _vm.isSelectedAll = $$v
                        },
                        expression: "isSelectedAll",
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(checkbox)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.$can(
                      "Ponto do Professor - Gerar Relatório",
                      "Pedagógico"
                    )
                      ? _c("b-form-checkbox", {
                          on: {
                            change: function ($event) {
                              return _vm.selectLesson(item)
                            },
                          },
                          model: {
                            value: item.selected,
                            callback: function ($$v) {
                              _vm.$set(item, "selected", $$v)
                            },
                            expression: "item.selected",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "head(devolution_pilots)",
                fn: function (data) {
                  return [
                    _c("span", { domProps: { innerHTML: _vm._s(data.label) } }),
                  ]
                },
              },
              {
                key: "cell(discipline_title)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("b-media", { attrs: { "vertical-align": "center" } }, [
                      _c("div", { staticClass: "d-block" }, [
                        _vm._v(" " + _vm._s(item.discipline_title) + " "),
                      ]),
                      _c(
                        "small",
                        {
                          staticClass: "text-secondary",
                          staticStyle: { "font-size": "0.9rem" },
                        },
                        [_vm._v(_vm._s(item.course_title))]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(date)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm._f("datePT")(item.date)) + " "),
                  ]
                },
              },
              {
                key: "cell(classrooms)",
                fn: function (ref) {
                  var item = ref.item
                  return _vm._l(item.classrooms, function (element, index) {
                    return _c("span", { key: index }, [
                      _vm._v(" " + _vm._s(element.classroom_name) + " "),
                    ])
                  })
                },
              },
              {
                key: "cell(arrival_time)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.arrival_time
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(item.arrival_time) +
                              " às " +
                              _vm._s(item.departure_time) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(devolution_pilots)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.devolution_pilots
                      ? _c("div", [
                          item.devolution_pilots.code === 1
                            ? _c("span", { staticClass: "text-dark" }, [
                                _vm._v("Ok"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(signature)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.signature
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("datePT")(item.signature, true)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(start_time)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.start_time
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(item.start_time) +
                              " às " +
                              _vm._s(item.end_time) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(teacher_type)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.teacher_type
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(item.teacher_type.toUpperCase()) + " "
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "cell(real_workload)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.real_workload
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertDecimalHoursToTime(
                                  item.real_workload
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(teacher_value_higher)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.teacher_value_higher
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(item.teacher_value_higher)
                            )
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "cell(teacher_value_lower)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.teacher_value_lower
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(item.teacher_value_lower)
                            )
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-create-signature",
                "modal-class": "modal-create",
                title: "Detalhes da aula",
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitSignature.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(" Você selecionou "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.selectedLessons.length) + " aulas."),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Horário chegada "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formItem.arrival_time,
                              expression: "formItem.arrival_time",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.formItem.arrival_time.$error,
                          },
                          attrs: { type: "time", placeholder: "00:00" },
                          domProps: { value: _vm.formItem.arrival_time },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formItem,
                                "arrival_time",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Horário saída "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formItem.departure_time,
                              expression: "formItem.departure_time",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.formItem.departure_time.$error,
                          },
                          attrs: { type: "time", placeholder: "00:00" },
                          domProps: { value: _vm.formItem.departure_time },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formItem,
                                "departure_time",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Dev. pilots"),
                          ]),
                          _c("v-select", {
                            class: {
                              "is-invalid":
                                _vm.$v.formItem.devolution_pilots.$error,
                            },
                            attrs: {
                              label: "title",
                              required: "",
                              options: _vm.optionsDevPilots,
                              clearable: false,
                              searchable: false,
                            },
                            model: {
                              value: _vm.formItem.devolution_pilots,
                              callback: function ($$v) {
                                _vm.$set(_vm.formItem, "devolution_pilots", $$v)
                              },
                              expression: "formItem.devolution_pilots",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-4 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-create-signature"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submitedTeacherPoint,
                              text: "Salvar",
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: _vm.filters.currentPage,
              perPage: _vm.filters.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col-md-3" }),
      _c("div", { staticClass: "col-md-9" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }